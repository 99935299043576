import React, { useCallback } from "react"
import { useMutation } from "@apollo/client"
import { Query } from "@apollo/client/react/components"
import { RouteChildrenProps } from "react-router"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import FormGroup from "@material-ui/core/FormGroup"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import { useForm } from "react-hook-form"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

import Layout from "../components/Layout"
import LoadingView from "../components/LoadingView"
import { DbRow } from "../../../common/db-typings"
import { ADMIN_SWAP_BAD_EVENT_WITH_GOOD_EVENT_MUTATION, ADMIN_SQL_QUERY } from "../queries"
import { AdminSQLQuery, AdminSQLQueryVariables } from "../../../__generated__/AdminSQLQuery"
import {
  adminSwapBadEventIdWithGoodEventId,
  adminSwapBadEventIdWithGoodEventIdVariables,
} from "../../../__generated__/adminSwapBadEventIdWithGoodEventId"
import { Box, Toolbar } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      padding: "20px 0",
    },
  }),
)

interface ISwapEventIdsFormProps {
  badEvent: DbRow
  goodEvent: DbRow
  awayTeam?: DbRow
  homeTeam?: DbRow
  mutate: any
  isSaving: boolean
}

const SwapEventIdsForm: React.FC<ISwapEventIdsFormProps> = ({ badEvent, goodEvent, awayTeam, homeTeam, mutate, isSaving }) => {
  const classes = useStyles()
  const [affectedPeriodIds, setAffectedPeriodIds] = React.useState<Array<number> | undefined>(undefined)

  const { register, handleSubmit } = useForm()

  const onSubmit = async () => {
    await mutate({
      variables: {
        badEventId: badEvent.id,
        goodEventId: goodEvent.id,
        affectedPeriodIds,
      },
    })
  }

  const handleSetPeriodIds = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const periodIds = JSON.parse(event?.target?.value)
      setAffectedPeriodIds(Array.isArray(periodIds) ? periodIds : undefined)
    } catch (err) {
      throw err
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Typography component="h5">
        Event - {awayTeam?.abbrev} @ {homeTeam?.abbrev}
      </Typography>
      <FormGroup row={true}>
        <TextField
          id="affectedPeriodIds"
          label="Affected PeriodIds"
          placeholder="[12,34]"
          type="string"
          onChange={handleSetPeriodIds}
          margin="normal"
          name={"affectedPeriodIds"}
          size={"medium"}
          inputRef={register}
        />
      </FormGroup>
      <Button variant="contained" color="primary" disabled={isSaving} type="submit">
        {isSaving ? `Saving...` : `Save`}
      </Button>
    </form>
  )
}

interface IEventInputFieldForm {
  badEventId?: number
  goodEventId?: number
  index: number
  setEventIdsToLookUp: (badEventId: number, goodEventId: number, index: number) => void
  removeBadEvent: (index: number) => void
}
const EventInputFieldForm: React.FC<IEventInputFieldForm> = (props) => {
  const [badEventId, setBadEventId] = React.useState<number | undefined>(props.badEventId)
  const [goodEventId, setGoodEventId] = React.useState<number | undefined>(props.goodEventId)

  const { index, setEventIdsToLookUp, removeBadEvent } = props

  const handleSetBadEventId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBadEventId(Number(event?.target?.value) || 0)
  }

  const handleSetGoodEventId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGoodEventId(Number(event?.target?.value) || 0)
  }

  return (
    <FormGroup row={true}>
      <TextField
        id="badId"
        label="Bad Id"
        placeholder="123"
        type="number"
        onChange={handleSetBadEventId}
        margin="normal"
        name={"badId"}
        size={"medium"}
        required={true}
        value={badEventId || ""}
      />
      <TextField
        id="goodId"
        label="Good Id"
        placeholder="123"
        type="number"
        onChange={handleSetGoodEventId}
        margin="normal"
        name={"goodId"}
        size={"medium"}
        required={true}
        value={goodEventId || ""}
      />
      <Button
        color="primary"
        onClick={() => {
          badEventId && goodEventId && setEventIdsToLookUp(badEventId, goodEventId, index)
        }}
        size="medium"
      >
        Lookup Event
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          removeBadEvent(index)
        }}
        size="medium"
      >
        Remove Event
      </Button>
    </FormGroup>
  )
}

interface IBadEventIdToGoodEventId {
  badEventId?: number
  goodEventId?: number
}

const SwapEventIds: React.FC<RouteChildrenProps> = ({ location, history }) => {
  const [badEventIdsToGoodEventIds, setBadEventIdsToGoodEventIds] = React.useState<Array<IBadEventIdToGoodEventId>>([
    { badEventId: undefined, goodEventId: undefined },
  ])

  const addBadEvent = useCallback(() => {
    setBadEventIdsToGoodEventIds([...badEventIdsToGoodEventIds, { badEventId: undefined, goodEventId: undefined }])
  }, [badEventIdsToGoodEventIds])

  const removeBadEvent = useCallback(
    (index: number) => {
      const copyArr = [...badEventIdsToGoodEventIds]
      copyArr.splice(index, 1)
      setBadEventIdsToGoodEventIds(copyArr)
    },
    [badEventIdsToGoodEventIds],
  )

  const setEventIdsToLookUp = useCallback(
    (badEventId: number, goodEventId: number, index: number) => {
      badEventIdsToGoodEventIds[index] = { badEventId, goodEventId }
      setBadEventIdsToGoodEventIds([...badEventIdsToGoodEventIds])
    },
    [badEventIdsToGoodEventIds],
  )

  const [mutate, mutationResult] = useMutation<adminSwapBadEventIdWithGoodEventId, adminSwapBadEventIdWithGoodEventIdVariables>(
    ADMIN_SWAP_BAD_EVENT_WITH_GOOD_EVENT_MUTATION,
  )

  return (
    <Layout title={`Scripts - Swap Events With Bad Ids`}>
      <Typography component="h5">Bad Event Ids To Good Event Ids</Typography>
      {badEventIdsToGoodEventIds.map((badEventIdsToGoodEventId, index) => {
        const { badEventId, goodEventId } = badEventIdsToGoodEventId
        return (
          <React.Fragment key={index}>
            {(badEventId && goodEventId && (
              <Query<AdminSQLQuery, AdminSQLQueryVariables>
                query={ADMIN_SQL_QUERY}
                variables={{ statement: `SELECT * from Event where Event.id = ${badEventId} or Event.id = ${goodEventId}` }}
              >
                {(eventsQuery) => {
                  const badEvent = eventsQuery.data?.sql?.find((e) => e.id === badEventId)
                  const goodEvent = eventsQuery.data?.sql?.find((e) => e.id === goodEventId)
                  return (
                    (
                      <>
                        {(badEvent && goodEvent && (
                          <Query<AdminSQLQuery, AdminSQLQueryVariables>
                            query={ADMIN_SQL_QUERY}
                            variables={{
                              statement: `SELECT * from Team where Team.id = ${goodEvent.awayTeamId} or Team.id = ${goodEvent.homeTeamId}`,
                            }}
                          >
                            {(teamsQuery) => {
                              const teams = teamsQuery?.data?.sql
                              const awayTeam = teams?.find((t) => t.id === goodEvent.awayTeamId)
                              const homeTeam = teams?.find((t) => t.id === goodEvent.homeTeamId)
                              return (
                                <>
                                  {homeTeam && awayTeam && (
                                    <SwapEventIdsForm
                                      badEvent={badEvent}
                                      goodEvent={goodEvent}
                                      awayTeam={awayTeam}
                                      homeTeam={homeTeam}
                                      mutate={mutate}
                                      isSaving={mutationResult.loading}
                                    />
                                  )}
                                  {teamsQuery.loading && <LoadingView />}
                                </>
                              )
                            }}
                          </Query>
                        )) ||
                          (eventsQuery.loading && <LoadingView />)}
                      </>
                    ) ||
                    (badEventId && <Typography component="h6">{`Event with id ${goodEventId} not found`}</Typography>) ||
                    (goodEventId && <Typography component="h6">{`Event with id ${badEventId} not found`}</Typography>) || (
                      <Typography component="h6">{`Events with ids ${badEventId} and ${goodEventId} not found`}</Typography>
                    )
                  )
                }}
              </Query>
            )) || (
              <EventInputFieldForm
                index={index}
                badEventId={badEventId}
                goodEventId={goodEventId}
                setEventIdsToLookUp={setEventIdsToLookUp}
                removeBadEvent={removeBadEvent}
              />
            )}
          </React.Fragment>
        )
      })}
      <Box mt={2}>
        <Toolbar>
          <Button color="primary" onClick={addBadEvent} size="medium">
            Add Bad Event Id
          </Button>
        </Toolbar>
      </Box>
      <Divider light={true} style={{ margin: "40px" }} />
    </Layout>
  )
}

export default SwapEventIds
